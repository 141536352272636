import React, {useEffect, useState} from "react";
import {AppLayout} from "@amzn/awsui-components-react";
import Solution from "../components/solutions/solutiondetails/Solution";
import HubNavigation from "../components/hubnavigation";
import Breadcrumbs from "../components/common/Breadcumbs";
import Notification from "../components/common/Notification";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {notificationTypeEnum} from "../constants/Constants";
const SolutionMainPage: React.FC = () => {

    const notificationType = useSelector((state: RootState) => state.notification.notificationType);
    const content = useSelector((state: RootState) => state.notification.content);
    const eventType = useSelector((state: RootState) => state.notification.eventType);

    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        if (notificationType === notificationTypeEnum.EDITSOLUTION || notificationType === notificationTypeEnum.DELETESOLUTION) {
            setShowNotification(true);
        }
    }, [notificationType, content, eventType])

    const [breadcrumbs, setBreadcrumbs] = useState(
        [{
            text: "ISV Industry Solutions",
            href: "/"
        },
        {
            text: "Solution",
            href: "/"
        }]
    );

    return (
        <AppLayout
            contentType="wizard"
            headerSelector="#h"
            content={<Solution breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs}/>}
            navigation={<HubNavigation />}
            breadcrumbs={<Breadcrumbs resourceDetailBreadcrumbs={breadcrumbs}/>}
            notifications={<Notification showNotification={showNotification} type={eventType} message={content}/>}
        />
    )
}

export default SolutionMainPage;