import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {notificationTypeEnum} from "../constants/Constants";
import {AppLayout} from "@amzn/awsui-components-react";
import HubNavigation from "../components/hubnavigation";
import Notifications from "../components/common/Notification";
import Partners from "../components/partner/partnerpage/Partners";

const PartnersPage: React.FC = () => {
    const notificationType = useSelector((state: RootState) => state.notification.notificationType);
    const content = useSelector((state: RootState) => state.notification.content);
    const eventType = useSelector((state: RootState) => state.notification.eventType);

    const [showNotification, setShowNotification] = React.useState(false);

    useEffect(() => {
        if (notificationType === notificationTypeEnum.DELETEPARTNER || notificationType === notificationTypeEnum.ADDPARTNER) {
            setShowNotification(true);
        }
    }, [notificationType, content, eventType]);

    return (
        <AppLayout
            contentType="table"
            headerSelector="#h"
            content={<Partners/>}
            navigation={<HubNavigation activeHref="/partners"/>}
            notifications={<Notifications showNotification={showNotification} type={eventType} message={content} />}
        />
    );
}

export default PartnersPage;